import Home from "./pages/Home"
import Dächer from "./pages/Dächer"
import Navbar from "./Navbar"
import Solar from "./pages/Solarsysteme"
import Über from "./pages/Über_uns"
import Partner from "./pages/Partnerfirmen"
import Ref from "./pages/Refferenzen"
import Jobs from "./pages/Jobs"
import Kontakt from "./pages/Kontakt"
import Footer from "./Footer"
import Impressum from "./pages/Impressum"
import Datenschutz from "./pages/Datenschutz"
import News from "./pages/News"
import { Ref1, Ref2, Ref3, Ref4 } from "./pages/Refs"
import { Example } from "./Example";
import { Route, Routes } from "react-router-dom"
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import MediaQuery from 'react-responsive';
import React from 'react'


function App() {
  return (
    <>
      <MediaQuery query="(min-device-width: 700px)">
         <Navbar/>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 700px)">
         <Example/>
        </MediaQuery>
     
     
      <div className=' overflow-hidden'>
      <div className='h-[50px]' ></div>
      <Routes>
          <Route path="/Ref1" element={<Ref1 />} />
          <Route path="/Ref2" element={<Ref2 />} />
          <Route path="/Ref3" element={<Ref3 />} />
          <Route path="/Ref4" element={<Ref4 />} />
          <Route path="/Dächer" element={<Dächer/>}/>
          <Route path="/" element={<Home />} />
          
          <Route path="/Home" element={<Home />} />
          <Route path="/Solarsysteme" element={<Solar />} />
          <Route path="/Über_uns" element={<Über />} />
          <Route path="/Partnerfirmen" element={<Partner />} />
          <Route path="/Referenzen" element={<Ref />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="/Kontakt" element={<Kontakt />} />
          <Route path="/Impressum" element={<Impressum/>} />
          <Route path="/Datenschutz" element={<Datenschutz/>} />
          <Route path="/News" element={<News/>} />
        </Routes>
      </div>
      
      <Footer/>
    </>
  )

}


export default App