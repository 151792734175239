import React from 'react';

import Logo from './assets/Logo.png';

import { Link, useMatch, useResolvedPath } from "react-router-dom"

import Insta from './assets/Insta.webp'

const Navbar = () => {
  return (
  <header className='py-5 snav '>
    <div className=' '> 
      <div className='flex justify-between items-center'>
          <Link to="/Home"  >
          <img src={Logo} alt='logo'  className='limg ml-20' />
         </Link>
        <div>   
      <a href='https://instagram.com/behrenddach?igshid=YmMyMTA2M2Y='>
          <img src={Insta} alt='insta' className='insta'/>
          </a>
        </div>    
         <div className='con1'>
        <Link to="/Home" >
        <button className=' rounded-2xl btn font-bold btn-sm mr-10 ml-20 scale-110' >Home</button>
        </Link>
        <Link to="/News">
        <button className='btn btn-sm font-bold rounded-2xl mr-10 scale-110'>News</button>
        </Link>
        {/*<a>ㅤ</a>
         if these are done re-add them  <CustomLink to="/Dächer">
        <button className='btn btn-sm rounded-2xl '>DÄCHER</button>
        </CustomLink>
        <a>ㅤ</a>
        <CustomLink to="/Solarsysteme">
        <button className='btn btn-sm rounded-2xl'>PHOTOVOLTAIK</button>
        </CustomLink>*/}
        <Link to="/Über_uns">
        <button className='btn btn-sm rounded-2xl font-bold mr-10  scale-110'>ÜBER_UNS</button>
        </Link>
        <Link to="/Partnerfirmen">
        <button className='btn btn-sm rounded-2xl font-bold mr-10 scale-110'>PARTNERFIRMEN</button>
        </Link>
        <Link to="/Referenzen">
        <button className='btn btn-sm rounded-2xl font-bold mr-10 scale-110'>REFERENZEN</button>
        </Link>
        {/* this too <a>ㅤ</a>
        <CustomLink to="/Jobs">
        <button className='btn btn-sm rounded-2xl'>JOBS</button>
        </CustomLink>*/}
        <Link to="/Kontakt">
        <button className='btn btn-sm rounded-2xl font-bold mr-60 scale-110'>KONTAKT</button>
      </Link>
      </div>
      </div> 
    </div>
  </header>
  );
};

export default Navbar;
