import * as React from "react";
import { useRef , useState } from "react";
import { motion, sync, useCycle } from "framer-motion";
import {fadeIn} from '../variants'
import { useDimensions } from "../use-dimensions";
import { MenuToggle2 } from "../MenuToggle2";
import { Navigation2 } from "./Navigation2";
import { Example, Example2, Example3, Example4 } from "./Materielshow";
import Steildach from "../assets/Steildach.jpg"
import { Link } from "react-scroll" ;

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};





export default function Dächer() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [count, setCount] = useState(0);
    return <>
      <div id="scroll-container" >
        <div id="scroll-text" className=" headline text-[78px]">DÄCHER</div>
      </div>
      <div className="h-[100px]"></div>
      <div className="text-center mx-auto  "> 
  <a className="bh2 text-[45px] font-bold  ">
  Erfahren sie mehr über die Geschichte und Anwendung von verschiedenen Dächer 
  </a>
 </div>
 <div className="cover" id="Auswahl">
 <Link to="FD" activeClass='active' smooth={true} spy={true} offset={-200}>
 <div className="margin3 pfs font-bold text-[30px]">
 <button >Steildächer</button>
 </div>
 </Link>
 <motion.nav className="align-center"
 initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}>
      <motion.div className="sbackground2" variants={sidebar} />
      <Navigation2 />
      <MenuToggle2 toggle={() => toggleOpen()} />
 </motion.nav>
  <div className="margin4">
 <a className='font-bold text-[30px] pfs1'>Flachdächer</a>
 </div>
 <Link to="DS" activeClass='active' smooth={true} spy={true} offset={-200}>
 <div className="margin5 pfs font-bold text-[30px]">
 <button >Dämmsysteme</button>
 </div>
 </Link>
 </div>
 <nav className='fixed  bottom-2 lg:bottom-8 w-full overflow-hidden z-50 '>
    <div className= "container mx-auto "> 
     
    <div className='w-full bg-black/20 h-[96px] backdrop-blur-2x1 rounded-full max-w-[250px]  mx-auto px-5 flex justify-between text2x1 text-white/50 items-center cover'>
       <Link to='Auswahl' activeClass='active' smooth={true} spy={true} offset={-200} className='cursor-pointer  flex  items-center justify-center '>
           <a className="bh2 font-bold text-[50px]">Auswahl</a>
       </Link>
       
      </div>
     </div>
  </nav>
  <div className="h-[400px]"></div>
  <div className='container mx-auto shiftM ' id="FD" >
    <div className='flex flex-col lg:flex-row' >
      <div className='flex-1 flex flex-col justify-start items-center pictureM'>
      <a className='flex amarg' >
      <motion.img variants={fadeIn('right', 0.5)} initial="hidden" whileInView={'show'}  whileHover={{ scale: 1.05 }}whileTap={{ scale: 0.9 }}  src={Steildach} alt='' className='showcase rounded-xl borders1'/>
      
      </a>
      
      
      </div>
      <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='mobiletext shadow1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <h1 className='bh1 text-[45px] font-bold'>Steildächer</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Die Naturfarbe des Dachziegels wird durch die jeweiligen Metallanteile des Tones hervorgerufen z. B. bewirkt Eisenoxid die rote Färbung des Scherbens. Durchgefärbte Dachziegel werden durch Beimischen von Metalloxiden in das Ziegelgut hergestellt, z. B. bewirkt ein hoher Mangananteil eine dunkelbraune Färbung.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>Engobierte Dachziegel (matter Überzug auf der Oberfläche) werden hergestellt durch das Aufbringen einer speziellen Tonschlämme vor dem Brennvorgang. Kristall- und Edelengoben sind Engoben die durch Glasuranteile in unterschiedlichen Mengen vergütet werden.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}> Glasierte Ziegel haben auf ihrer Oberfläche einen glasierten Überzug, der die Oberfläche des Scherbens abdichtet und eine besondere Färbung ergibt. Die Glasur wird vor dem Brennen auf dem Rohling aufgebracht.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('left', 1.2)} initial="hidden" whileInView={'show'}>Gedämpfte oder reduzierte Ziegel werden im Reduktionsverfahren, d. h. durch Entzug des Sauerstoffes, in einem 2. Brand, aus eisenoxidhaltigem Ton hergestellt. Dabei können grau-blaue bzw. bräunliche Färbungen hergestellt werden.</motion.a>
        
      </motion.div>

    </div>
  </div>
  <div className="h-[300px]"></div>
  <div className=" bh2 text-[70px] text-center font-bold">
    <a >Materialien</a>
  </div>
  <div className="h-[75px]"></div>
  <div className="mx-auto container cover">
    <div className="pfs flex-col">
      <a className="text-[29px]">TonㅤㅤㅤㅤㅤㅤㅤSchieferㅤㅤㅤㅤㅤㅤㅤBetondachsteineㅤㅤㅤㅤㅤㅤㅤFaserzement</a> 
    </div>
    <div className="example-container margin6 ">
        <Example key={count} />
      </div>
      <div className="example-container2 margin7">
        <Example2 key={count} />
      </div>
      <div className="example-container3 margin8">
        <Example3 key={count} />
      </div>
      <div className="example-container4 margin9">
        <Example4 key={count} />
      </div>
  </div>
  <div className="h-[200px]"></div>
  <div className='container mx-auto shiftM '  id="Ton">
    <div className='flex flex-col lg:flex-row'>
      
      <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='leftmarg mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 amarg'>
        <div className='bh1 text-[35px] font-bold text-center'><a>STEILDÄCHER TONDACHZIEGEL</a></div>
        <motion.a className='pfs1' variants={fadeIn('right', 0.6)} initial="hidden" whileInView={'show'} ><a className="font-bold">Grundprodukt:</a><br />Ein Teil des Urgesteins ist der Feldspat, der u.a. aus komplexen Aluminiumverbindungen mit dem Silicium und Kalium besteht. Feldspat verwittert und bildet als unlösliches Zerfallergebnis den Ton. Der Ton ist nach seiner chemischen Zusammensetzung unterschiedlich gefärbt, durch Eisenoxid rotbraun, durch Mangan braun bis schwarz usw. Die bewährten Eigenschaften des Tones beruhen auf seiner Kristallgitterstruktur, die von den Elementen der jeweiligen Verbindung aufgebaut werden. Diese Struktur bewirkt bei Zusatz von Wasser die platische Formbarkeit. Nach dem Brennen verschmelzen die Kristallkörper und erstarren zu einem fertigen Produkt.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('right', 0.8)} initial="hidden" whileInView={'show'}><a className="font-bold">Produktion:</a><br />Zur Herstellung hochwertiger Ziegel ist es erforderlich, die geeigneten Tonqualitäten zu verarbeiten, bei Einlagerung von Quarzmetall spricht man von Lehm, sind hohe Kalkeinmischungen vorhanden, wird der Rohstoff als Mergel bezeichnet. Der Abbau des Tones erfolgt im Tagebau mit Schaufelradbaggern. Für Dachziegel verwendet man Lehm mit einem Tonanteil von 40-60%. Die richtige Tonmischung wird nachträglich herbeigeführt, ggf. durch Zuführen von Quarzsand, fettere Tone usw.Im Ton-Aufbereitungsverfahren werden die Ablagerungsstrukturen zerstört, indem sie durch den Zusatz von Wasser aufgequollen werden (Sumpfverfahren). In schweren Walzwerken (kollergang) werden Verunreinigungen zermahlen, in Tonmischer Flußmittel, Baryt, Magerungsmittel zugesetzt. In weiteren Walzvorgängen sowie der Lochsiebpresse wird durch Zugabe von Wasser eine homogene, formbare Masse mit einer Korngröße bis 1mm hergestellt. Die Dachziegelformen werden nun in der Strangpresse (Hohlpfanne, Biber usw.) bzw. in der Strang- und Revolverpresse der Preßdachziegel hergestellt. DAs Brenne der Dachziegel erfolgt nach einer langsamen Trocknung bei 900-1100°C, je nach Eisengehalt des Tones. Anschließend erfolgt eine langsame Abkühlung. Beim Brenne wird durch chemische Vorgänge das Verfestigen des Ziegels erreicht, die richtige Steuerung des Brennvorganges ist Voraussetzung für Maßhaltigkeit, Farbe und Funktionsfähigkeit.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('right', 1)} initial="hidden" whileInView={'show'}><Link to="MehrInf" activeClass='active' smooth={true} spy={true} offset={-200}><button className='btn btn-lg text-[25px] rborder'>Mehr Info</button></Link> </motion.a>
        
        
      </motion.div>
      <motion.div variants={fadeIn('left', 0.4)} initial="hidden" whileInView={'show'} className='shiftMalt2 mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 '>
        <h1 className='bh1 text-[35px] font-bold text-center'>STEILDÄCHER SCHIEFER</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Unter dem Begriff Schiefer fasst man alle Gesteine zusammen, die sich von Natur aus spalten lassen. Das sind meistens Sedimentgesteine (Ablagerungsgesteine), entsprechend ihrem mineralogischen Aufbau unterscheidet man Kupfer-, Kalk-, Alaun-, Öl- und Tonschiefer. Dachschiefer wird aus Tonschiefer gewonnen und ist ein natürliches Sedimentgestein von feinkörnigem Gefüge, das sich in dünne Schichten spalten lässt.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>Die planmäßige Schiefergewinnung reicht bis in die Römerzeit zurück, bereits 450 n. Chr. hat es in ihren rheinischen Siedlungen schiefergedeckte Bauten gegeben. In Trier hat es bereits um 1100 n. Chr. eine Leyendeckerzunft gegeben, 1289 n. Chr. erhielt die Kaiserpfalz in Goslar ein schiefergedecktes Dach. Seit dem Spätmittelalter wird ständig Schiefer abgebaut. Zunächst im Tagebau, seit 1880 in Schieferbergwerken. In den anderen europäischen Abbaugebieten (Spanien und Wales) wird noch heute im Tagebau Schiefer gewonnen.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}>Der europäische Dachschiefer ist ca. 400 Millionen Jahre alt, er entstand aus den Ablagerungen gewaltiger Ströme, die den Schlamm abgetragener Gebirge transportierten. Die abgelagerten Sedimente Tonschlamm, der durch hohen Druck darüberliegender Geröll- und Wassermassen verdichtet und verfestigt wurde. Durch seitlichen Druck (Auffaltungen) wurden Mineralkristalle und Schichtsilikate senkrecht zum Druck ausgerichtet und kristallisierten zu parallelen, vernetzten Lagen aus – durch diese besondere Kristallisation ist das Schiefergestein spaltbar.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('left', 1.2)} initial="hidden" whileInView={'show'}>Die Qualitätsanforderungen des Dachschiefers umfassen neben seiner chemischen Zusammensetzung und Eignung, die leichte Bearbeitbarkeit. Es soll beim Spalten eine weitgehend glatte und ebene Platte entstehen. Die Bruchfestigkeit soll hoch, die Härte nicht allzu groß sein, Farbbeständigkeit muss gegeben sein, Haarrisse, Adern und Knollen dürfen nicht enthalten sein.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('left', 1.4)} initial="hidden" whileInView={'show'}>Dacheindeckungen und Wandverkleidungen aus Schiefer gehören zu den besonders regensicheren Deckungen, was durch die Formgebung der Decksteine sowie die geringe Kapillarität unterstützt wird. Darüber hinaus zeichnet sich hochwertiger Schiefer durch sehr lange Lebensdauer und sehr ästhetische Dachflächen aus.</motion.a>
      </motion.div>
      
      

    </div>
  </div>
  <div className="h-[200px]" ></div>
  
      
  <div className='container mx-auto' id="BD">
    <div className='flex flex-col lg:flex-row'>
      
      <motion.div variants={fadeIn('down', 0.2)} initial="hidden" whileInView={'show'} className='STM mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 amarg'>
        <div className='bh1 text-[35px] font-bold text-center'><a>STEILDÄCHER BETONDACHSTEINE</a></div>
        <motion.a className='pfs1' variants={fadeIn('down', 0.6)} initial="hidden" whileInView={'show'}>Die ersten Betondachsteine wurden im Jahre 1840 in Bayern produziert. Zu Anfang des 20. Jahrhunderts wurde das Produktionsverfahren von den Engländern übernommen und weiterentwickelt. Erst 1952 wurden auch in Deutschland wieder im großen Umfang Betondachsteine produziert – die Firma Braas übernahm englische Produktionsmethoden und führte die Fließbandfertigung ein.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('down', 0.8)} initial="hidden" whileInView={'show'}>In den Jahren nach dem 2. Weltkrieg wurden von der Firma Gebr. Behrend aus der Kiesgrube auf dem Betriebsgelände Kies abgebaut, daraus Betonpfannen in Hohlpfannen-Form hergestellt. Die damit eingedeckten Dächer haben jahrzehntelang gehalten!</motion.a>
        <motion.a className='pfs1' variants={fadeIn('down', 1)} initial="hidden" whileInView={'show'}>Betondachsteine bestehen aus Quarzsand, Körnung 0/3, Portlandzement, Farbpigmenten (künstliche Metalloxide), die den Stein durchfärben. Zusätzlich erfolgt eine Oberflächenfärbung aus gefärbter Zementschlämme sowie eine Oberflächenbeschichtung aus Acryl-Dispersionen.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('down', 1.2)} initial="hidden" whileInView={'show'}>Durch die Herstellung im Strangverfahren sind aufwendige Verfalzungen nicht möglich, durch fehlende Kopfverfalzung ist der Wetterschutz in extremen Situationen eingeschränkt. Dann sind zusätzliche Maßnahmen wie z. B. Unterdächer zu empfehlen.</motion.a>
        
      </motion.div>
      <div className="h-[100px]"></div>
      <motion.div variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} className='mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 '>
        <h1 className='bh1 text-[35px] font-bold text-center'>STEILDÄCHER FASERZEMENT</h1>
        <motion.a className='pfs1' variants={fadeIn('up', 0.6)} initial="hidden" whileInView={'show'}>Die Faserzement-Bauplatte wurde im Jahr 1900 durch den Österreicher Ludwig Hatscheck erfunden und als Patent zur „Herstellung von Kunststeinplatten aus Faserstoffen und hydraulischen Bindemitteln“ patentiert.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('up', 0.8)} initial="hidden" whileInView={'show'}>Auf der Suche nach einem geeigneten Armierungsmaterial wurde eher zufällig die Asbestfaser entdeckt. Daraus entwickelte sich der Vorläufer der heutigen Faserzement-Platte die Asbestzement-Tafel.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('up', 1)} initial="hidden" whileInView={'show'}>Anfang der achtziger Jahre haben Erkenntnisse über die Gesundheitsgefährdung der Asbestfasern zu einer Änderung der Produktionsbedingungen geführt – das Verwendungsverbot für Asbestzement-Produkte trat 1992 in Kraft.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('up', 1.2)} initial="hidden" whileInView={'show'}>Heute werden zur Armierung für Faserzementprodukte Polyacrylfasern, Kunststoff-Fasern auf der Basis von Polyvinylalkohol oder auch Zellulosefasern verwendet, als Bindemittel dienen benormte Zemente, die die hohe Zug- und Biegefestigkeit der Faser mit hoher Druckfestigkeit verbinden.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('up', 1.4)} initial="hidden" whileInView={'show'}>Der Produktionsvorgang erfolgt heute automatisch, Faserbündel werden im Rührwerk zu Feinfasern aufgelöst und unter Zusatz von Wasser und Zement vermischt. Bei der Tafelfertigung wird das Rohmaterial über Stoffkästen, Formatwalzen, in Plattenmaschinen gefördert, dort formatiert und anschließend abgelagert.</motion.a>
        <motion.a className="pfs1" variants={fadeIn('up', 1.6)} initial="hidden" whileInView={'show'}>Alle Faserzementerzeugnisse können durchgefärbt werden oder naturfarben verbleiben, eine Oberflächenbeschichtung kann aus keramischen Stoffen hergestellt werden oder aus einer überzogenen Kunststoffschicht bestehen.</motion.a>
      </motion.div>
      

    </div>
  </div>
  <div className="h-[200px]"></div>
  <div className='container mx-auto' id="MehrInf">
    <div className='flex flex-col lg:flex-row'>
      
      <motion.div variants={fadeIn('down', 0.2)} initial="hidden" whileInView={'show'} className='STM mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 amarg'>
        <div className='pfs1 text-[30px] font-bold text-center'><a>Hohlpfannenziegel</a></div>
        <motion.a className='pfs1' variants={fadeIn('down', 0.6)} initial="hidden" whileInView={'show'}>Die Hohlpfanne ist an ihrer besonders tiefgeschwungenen Form erkennbar. Ihre Heimat ist Norddeutschland. Viele historische Gebäude sind mit Hohlpfannen eingedeckt. Auch für Wandverkleidungen ist sie in einigen Gegenden verwendet worden.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('down', 0.8)} initial="hidden" whileInView={'show'}>Geschwungene Gauben (Fledermausgaube) sind ihr Metier - hier zeigt sie ihre ganze Schönheit und ihre vielfältigen Einsatzmöglichkeiten. Mindestdachneigung:</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('down', 1)} initial="hidden" whileInView={'show'}>Vorschnittdeckung 40°</motion.a>
        <motion.a className="pfs1 font-bold" variants={fadeIn('down', 1.2)} initial="hidden" whileInView={'show'}>Vorschnittdeckung 35°</motion.a>
        
      </motion.div><div className="h-[100px]"></div>
      <motion.div variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} className='STM mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 '>
        <h1 className='pfs1 text-[30px] font-bold text-center'>Biberschwanzziegel</h1>
        <motion.a className='pfs1' variants={fadeIn('up', 0.6)} initial="hidden" whileInView={'show'}>Der Biberschwanzziegel eignet sich besonders für die individuelle Gestaltung der kleinteiligen Dächer, wie zum Beispiel Gauben, Kuppeln, Türme und Kamine. Außerdem können untergelegte und eingebundene Biberschwanzkehlen ausgeführt werden. Man unterscheidet:  </motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 0.8)} initial="hidden" whileInView={'show'}>Doppeldeckung</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 1)} initial="hidden" whileInView={'show'}>Kronendeckung</motion.a>
        
      </motion.div>
      
          
    </div>
  </div>
  <div className="h-[300px]"></div>
  <div className="text-center w-full font-bold bh1 text-[50px] h-[200px] "><a>DÄMMSYSTEME</a></div>
  <div className='container mx-auto' id="DS">
    <div className='flex flex-col lg:flex-row'>
      
      <motion.div variants={fadeIn('down', 0.2)} initial="hidden" whileInView={'show'} className=' mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 '>
        <div className='bh2 text-[30px] font-bold text-center'><a>AUFSPARRENDÄMMSYSTEM FÜR STEILDÄCHER</a></div>
        <motion.a className='pfs1' variants={fadeIn('down', 0.6)} initial="hidden" whileInView={'show'}>Aufsparrendämmsysteme werden als durchgehende Wärmedämmung oberhalb des Dachstuhles verlegt. Weil dieses Dämmsystem ohne Unterbrechungen (durch Sparren) verlegt wird und die Dämmelemente über ein Nut- und Federsystem sowie über überlappende Kaschierungen verfügen, ist es die effektivste Form der Steildachdämmung.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('down', 0.8)} initial="hidden" whileInView={'show'}>Es wird gleichermaßen der sommerliche Hitzeschutz wie der gewünschte winterliche Dämmwert erreicht.</motion.a>
        <motion.a className='pfs1 ' variants={fadeIn('down', 1)} initial="hidden" whileInView={'show'}>Voraussetzung für den Erfolg der Maßnahme ist das funktionsfähige und winddichte Anschließen von Trauf- und Giebelmauerwerk, Dachflächenfenstern, Gauben, Dunstrohren, sonstiger Einbauteile usw.</motion.a>
        
        
      </motion.div>
      <div className="h-[100px]"></div><div className="w-[100px]"></div>
      <motion.div variants={fadeIn('down', 0.2)} initial="hidden" whileInView={'show'} className='  mobiletext borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 '>
        <div className='bh2 text-[30px] font-bold text-center'><a>GEFÄLLEDÄMMSYSTEME FÜR FLACHDÄCHER</a></div>
        <motion.a className='pfs1' variants={fadeIn('down', 0.6)} initial="hidden" whileInView={'show'}>Gefälledämmsysteme kommen sowohl im Neubau wie auch in der Sanierung zur Anwendung:</motion.a>
        <motion.a className='pfs1' variants={fadeIn('down', 0.8)} initial="hidden" whileInView={'show'}>Sie haben folgende Aufgaben:</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('down', 1)} initial="hidden" whileInView={'show'}>Die Dachabdichtung wird durch die Gefälleausbildung, entsprechend der Flachdachregeln, mit mindestens 2 % Gefälle zum Entwässerungspunkt ausgestattet</motion.a>
        <motion.a className="pfs1 font-bold" variants={fadeIn('down', 1.1)} initial="hidden" whileInView={'show'}>Die Gefälledämmplatten haben energetisch die gleiche Wirkung wie ebene Dämmplatten. Durch das Aufbringen eines Gefälleleichtdaches im Rahmen einer Sanierung lässt sich somit der geforderte U-Wert, gemäß der Energie-Einsparverordnung erreichen</motion.a>
        <motion.a className='pfs1' variants={fadeIn('down', 1.1)} initial="hidden" whileInView={'show'}>Gefällesysteme werden aus folgenden Materialien hergestellt:</motion.a>
        <motion.a className="pfs1 font-bold" variants={fadeIn('down', 1.2)} initial="hidden" whileInView={'show'}>Styropor EPS WLG 040-032</motion.a>
        <motion.a className="pfs1 font-bold" variants={fadeIn('down', 1.3)} initial="hidden" whileInView={'show'}>PIR Polyisocyanurat WLG 030</motion.a>
        <motion.a className="pfs1 font-bold" variants={fadeIn('down', 1.4)} initial="hidden" whileInView={'show'}>Mineralfaserdämmplatten WLG 040-035</motion.a>
        <motion.a className="pfs1 font-bold" variants={fadeIn('down', 1.5)} initial="hidden" whileInView={'show'}>Schaumglas WLG 040</motion.a>
        
      </motion.div>
          
    </div>
  </div>  
<div className="h-[600px]"></div>
</>
 }