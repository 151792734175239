import React from 'react';
import {TypeAnimation} from 'react-type-animation' 
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom"
import newDach from '../assets/Neuba.png'
import Ref2 from '../assets/Ref2.jpeg'
import Ref3 from '../assets/Ref3.jpeg'
import Ref4 from '../assets/Ref4.jpeg'
import { Helmet } from 'react-helmet';


const Ref = () => {
  

  
  return ( 
  
  
   <> 
   <Helmet>
                <meta charSet="utf-8" />
                <title>Partnerfirmen</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Partnerfirmen" />
            </Helmet>
  <div id="scroll-container" >
    <div id="scroll-text" className=" headline text-[78px]">REFERENZEN</div>
 </div>
 <div className='h-[50px]'></div>
 <div className=' text-center'>
 <a className='bh2 text-[25px] font-bold '>Hier sind einige unserer letzten Bauprojekte als Referenzen für unsere Arbeit</a>
 <div className='h-[25px]'></div>
 </div>
  <div className="h-[250px]"></div>
<div id="slideshow scaler">
			<div class="entire-content">
				<div class="content-carrousel">
           
					<figure class="shadow3"><CustomLink to="/Ref1" ><img alt='' className='scaleY' src={newDach}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref3" ><img alt=''   className='scaleY2' src={Ref3}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref2" ><img alt='' className='scaleY' src={Ref2}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref4"><img alt='' className='scaleY2' src={Ref4}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref1" ><img alt='' className='scaleY' src={newDach}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref2" ><img alt='' className='scaleY' src={Ref2}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref3" ><img alt='' className='scaleY2' src={Ref3}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref4"><img alt='' className='scaleY2' src={Ref4}/></CustomLink></figure>
					<figure class="shadow3"><CustomLink to="/Ref1" ><img alt='' className='scaleY' src={newDach}/></CustomLink></figure>
		</div>
	</div>
</div>

  

<div className='h-[300px]'></div>

</>
)
}

export default Ref

  function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
    return (
      <li className={isActive ? "active" : ""}>
        <Link to={to} {...props}>
          {children}
        </Link>
      </li>
    )
  }


 
