import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const colors = []

export const MenuItem2 = ({ i }) => {
  const style = { border: `2px solid ${colors[i]}` };
  return (
    <div className="font-bold">
    <motion.lis
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
     
      <div className="h-[10px]" ></div>
      <Link to="Ton" activeClass='active' smooth={true} spy={true} offset={-200}>
      <a className="bh2 text-[30px]" >Ton</a>  
      </Link> 
      
    </motion.lis> 
    <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <Link to="Ton" activeClass='active' smooth={true} spy={true} offset={-200}>
    <a className="bh2 text-[30px]" >Schiefer </a>  
    </Link> 
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <Link to="BD" activeClass='active' smooth={true} spy={true} offset={-200}>
    <a className="bh2 text-[30px]" >Betondachsteine</a>  
    </Link>
    
  </motion.lis>
  <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <Link to="BD" activeClass='active' smooth={true} spy={true} offset={-200} >
    <a className="bh2 text-[30px]" >Faserzement </a>  
    </Link>
    
  </motion.lis>

  </div>


  );
};

export const MenuItem3 = ({ i }) => {
  const style = { border: `2px solid ${colors[i]}` };
  return (
    <div className="font-bold">
    <motion.lis
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
     
      <div className="h-[10px]" ></div>
      <Link to="FD" activeClass='active' smooth={true} spy={true} offset={-200}>
      <a className="bh2 text-[30px]" >Integrierte Steildachlösung</a>  
       </Link>
      
    </motion.lis> 
    <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    
    <Link to="FD" activeClass='active' smooth={true} spy={true} offset={-200}>
      <a className="bh2 text-[30px]" >Integrierte Flachdachlösung</a>  
       </Link>

    
     
    
  </motion.lis>
  
 

  </div>


  );
};

export const MenuItem4 = ({ i }) => {
  const style = { border: `2px solid ${colors[i]}` };
  return (
    <div className="font-bold">
    <motion.lis
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
     
      <div className="h-[10px]" ></div>
      <Link  to="VS" activeClass='active' smooth={true} spy={true} offset={-200}>
      <a className="bh2 text-[30px]" >Velux - Solarkollektoren, Ihre Vorteile im Überblick</a>  
      </Link>
      
    </motion.lis> 
    <motion.lis
    variants={variants}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
   
    <div className="h-[10px]" ></div>
    <Link  to="VS" activeClass='active' smooth={true} spy={true} offset={-200}>
    <a className="bh2 text-[30px]" >Thermie- und Voltaik-Kollektoren </a>  
    </Link>
    
  </motion.lis>
  
 

  </div>


  );
};









