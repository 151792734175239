import * as React from "react";
import { useRef , useState } from "react";
import { motion, sync, useCycle } from "framer-motion";
import {fadeIn} from '../variants'
import { Navigation3, Navigation4 } from "./Navigation2";
import { MenuToggle3, MenuToggle4 } from "../MenuToggle2";
import { useDimensions } from "../use-dimensions";
import  Solar1  from "../assets/Solar.png"
import { Link } from "react-scroll" ;


const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};



export default function Solarsysteme() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [isOpen1, toggleOpen1] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [count, setCount] = useState(0);
  return <section>
  <div id="scroll-container" >
    <div id="scroll-text" className=" headline text-[78px]">Photovoltaik</div>
  </div>
  <div className="h-[150px]"></div>
  <div className="text-center mx-auto  "> 
  <a className="bh2 text-[45px] font-bold  ">
  Eine Ausführliche Erklärung von Photovoltaik
  </a>
 </div>
 <div className="h-[400px]"></div>
 <div className='container mx-auto' >
    <div className='flex flex-col lg:flex-row' >
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='  rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start shadow1'>
        <h1 className='bh1 text-[35px] font-bold '>ZUKUNFTSSICHERE ENERGIEVERSORGUNG</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Das theoretische Potenzial an Sonnenenergie ist enorm und nach menschlichen Maßstäben unerschöpflich. Mit dem Sonnenlicht strahlt 11000 Mal Energie auf die Erde als die Menschheit weltweit verbraucht. Um den jährlichen Weltenergiebedarf zu decken, reicht tehoretisch die in weniger als einer halben Stunde die Erde erreichende Sonnenenergie aus. Selbst in Deutschland liegt die Energiestrahlung der Sonne 80-fach über dem Bedarf an Primärenergie. Auch in unserem nördlichen Breiten kann dank moderner Solartechnik ein großer Teil unseres Energiebedarfs mittels Sonnenenergie gedeckt werden. Dieses Potenzial an Solarenergie lässt sich zur Stromerzeugung (Photovoltaik) oder zur Wärmeerzeugung (Solarthermie) nutzen.</motion.a>
        
        
      </motion.div>
      <div className='flex-1 flex flex-col justify-start items-center '>
      <a className='flex ' >
      <motion.img variants={fadeIn('right', 0.5)} initial="hidden" whileInView={'show'}  whileHover={{ scale: 1.05 }}whileTap={{ scale: 0.9 }}  src={Solar1} alt='' className='showcase sizex rounded-xl borders1'/>
      
      </a>
      
      
      </div>
      

    </div>
  </div>
  <div className="h-[400px]"></div>
  <div className='container mx-auto' id="Auswahl" >
    <div className='flex flex-col lg:flex-row' > 
      <Link to='Pt'activeClass='active' smooth={true} spy={true} offset={-200} className="cursor-pointer">
      <a className="pfs font-bold text-[45px]">Mehr</a>
      </Link>
      <motion.nav className="align-center"
 initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}>
      <motion.div className="sbackground3" variants={sidebar} />
      <Navigation3 />
      <MenuToggle3 toggle={() => toggleOpen()} />
 </motion.nav>
 
      
    </div>
  </div>
  <nav className='fixed  bottom-2 lg:bottom-8 w-full overflow-hidden z-50 '>
    <div className= "container mx-auto "> 
     
    <div className='w-full bg-black/20 h-[96px] backdrop-blur-2x1 rounded-full max-w-[250px]  mx-auto px-5 flex justify-between text2x1 text-white/50 items-center '>
       <Link to='Auswahl' activeClass='active' smooth={true} spy={true} offset={-200} className='cursor-pointer  flex  items-center justify-center '>
           <a className="bh2 font-bold text-[50px]">Auswahl</a>
       </Link>
       
      </div>
     </div>
  </nav>

  <div className="h-[300px]"></div>
  <div className='container mx-auto ' id='Pt'>
    <div className='flex flex-col lg:flex-row ' > 
    <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} className=' rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start '>
        <h1 className='bh1 text-[32.5px] font-bold '>NUTZUNG VON ERNEUERBAREN ENERGIEN DURCH SOLARANLAGEN</h1>
        <motion.a className='pfs1' variants={fadeIn('up', 0.6)} initial="hidden" whileInView={'show'}><span className="bh2 text-[35px] font-bold">PHOTOVOLTAIK</span></motion.a>
        <motion.a className='pfs1' variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'}>Unter Photovoltaik versteht man die direkte Umwandlung von Sonnenenergie in elektrische Energie mittels Solarzellen.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('up', 0.8)} initial="hidden" whileInView={'show'}>Der photoelektrische Effekt wurde durch den französischen Physiker A. B. Bequerel im Jahr 1839 nachgewiesen. Es wurden Photovoltaikzellen entwickelt, die ab 1960 zunächst in der Raumfahrt eingesetzt wurden.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('up', 0.9)} initial="hidden" whileInView={'show'}>In Deutschland hat die Anwendung der Photovoltaik durch das 100.00-Dächer-Programm sowie das Erneuerbare-Energien-Gesetz (EEG) erheblich zugenommen – es wurden durch das EEG finanzielle Anreize, in Form von Einspeisevergütungen, geschaffen.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('up', 1)} initial="hidden" whileInView={'show'}>Bei den PV-Systemen auf Dächern werden folgende Systeme unterschieden:</motion.a>
       
      </motion.div>
    </div>
  </div>
  <div className="h-[300px]"></div>
  <div className='container mx-auto' >
    <div className='flex flex-col lg:flex-row' > 
    <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className=' borders1 rounded-2xl flex  flex-1  flex-col gap-y-6  p-6 items-start amarg'>
        <h1 className='bh2 text-[30px] font-bold '>A: AUFDACHSYSTEM</h1>
        <motion.a className='pfs1 font-bold ' variants={fadeIn('right', 0.6)} initial="hidden" whileInView={'show'}>Auf Steildächern werden Module auf einem Trägersystem oberhalb der Dacheindeckung montiert – der Nachteil sind zahlreiche Durchdringungspunkte der Dacheindeckung</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('right', 0.8)} initial="hidden" whileInView={'show'}>Auf Flachdächern werden die Module auf Trägersystemen montiert, die über Fundamente oder Dachdurchdringungen gesichert werden</motion.a>
       
      </motion.div>

      <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className=' borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start '>
        <h1 className='bh2 text-[30px] font-bold '>B: INDACHSYSTEM</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Unter Indachsystemen versteht man den Einbau von PV-Modulen in der Ebene der Dacheindeckung oder der Dachhaut, deren Funktion sie ebenfalls übernehmen:</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>für Steildächer:das Roto Sunroof-Indach-System</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}>für Flachdächer:das Evalon-Photovoltaik-System aus dem Hause Alwitra</motion.a>
        <motion.a className='pfs1 ' variants={fadeIn('left', 1.2)} initial="hidden" whileInView={'show'}>Der gewonnene Strom wird in Wechselrichtern in Wechselstrom umgewandelt und kann dann in das öffentliche Netz eingespeist bzw. eigengenutzt verbraucht werden.</motion.a>
        <motion.a className='pfs1 ' variants={fadeIn('left', 1.4)} initial="hidden" whileInView={'show'}>Eine PV-Anlage erzielt pro KWpeak/ Jahr (das entspricht 8-10 m² Modulfläche) einen Ertrag von 700-1.180 KWh/ Jahr. Der Ertrag ist abhängig von Wetter, Beschattung und Ausrichtung.</motion.a>
      </motion.div>      
    </div>
  </div>
  <div className="h-[300px]"></div>
  <div className="bh2 text-[35px] font-bold"></div>
  <div className="bh2 text-[35px]  font-bold" id="FD"><span className="right-40 absolute marginidk">Integrierte Flachdachlösung</span>
  <span className="left-40 absolute ">Integrierte Steildachlösung</span>
  </div>
  <div className="h-[200px]"></div>
  <div className='container mx-auto' >
    <div className='flex flex-col lg:flex-row' > 
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className=' borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start amarg'>
        <h1 className='bh1 text-[30px] font-bold '>ROTO SOLARKOLLEKTOREN</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Mit den Roto-Solarkollektoren, die wegen ihres hohen Wirkungsgrafdes den blauen Umweltengel tragen, können im Jahresdurchschnitt rund 60% des benötigten Warmwassers erhitzt werden.</motion.a>
        <motion.a className='pfs1 ' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>Doch die Sonne kann nicht nur das Wasser für die Dusche und die Heizung erwärmen. Sie liefert auch den Strom für Fernseher, Waschmaschine und Herd. Mit sechs Photovoltaik-Modulen von Roto können, gemäß Herstellerangaben, bereits rund 50% des Strombedarfs eines Einfamilienhauses gedeckt werden.</motion.a>
        <motion.a className='pfs1 ' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}>Module von 1,96 * 1,4 m² stehen zur Verfügung. Jedes Modul liefert eine Leistung von 338 Wp (maximale Leistung - Peak). Der durch die Sonne erzeugte Strom wird in das öffentliche Netz eingespeißt und von den örtlichen Energieerzeugern - abhängig von der Anlagengröße - mit 0,54 € bis 0,57 € pro kWh vergütet.</motion.a>
        <motion.a className='pfs1 ' variants={fadeIn('left', 1.2)} initial="hidden" whileInView={'show'}>Neben den gesetzlich vorgeschriebenen Vergütungssätzen unterstützt der Staat Solarenergienutzung mit stattlichen Invenstitionszuschüssen und zinsgünstigen Darlehen. Zusammen mit den Wohndachfenstern von Roto bilden die Kollektoren eine optisch perfekte Einheit. Wir liefern, in Zusammenarbeit mit unsern Partnern, die komplette Anlage.</motion.a>
      </motion.div>      

      <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className=' borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start '>
        <h1 className='bh1 text-[30px] font-bold '>EVALON - SOLAR</h1>
        <motion.a className=' font-bold bh2' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>die erste Stromerzeugende Dachbahn auf dem Weltmarkt</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 0.7)} initial="hidden" whileInView={'show'}>hochwertige Kunststoffbahnen mit flexiblen und leichten Photovoltaik (PV) - Modulen</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>Schutz eines Bauwerks gegen Niederschlagswasser</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 0.9)} initial="hidden" whileInView={'show'}>Direkte Umwandlung von solarer Strahlungsenergie in elektrischen Strom</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}>Extrem niedriges Eigengewicht</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1.1)} initial="hidden" whileInView={'show'}>Flexibel, ohne Glas und Rahmen, passt sich jeder Dachform an</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1.2)} initial="hidden" whileInView={'show'}>Witterungsbeständig. Die PV-Module sind oberseitig transparent und schmutzabweisend mit TEFZEL und unterseitig mit EVA-haltiger Folie verkapselt</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1.3)} initial="hidden" whileInView={'show'}>In Leistung, Stromstärke und Spannung bedeutend weniger Temperaturabhängig als Module aus kristallinen Solarzellen</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1.4)} initial="hidden" whileInView={'show'}>Leistungsgarantie über 20 Jahre</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('left', 1.4)} initial="hidden" whileInView={'show'}>Hervorragend geeignet für Flachdachsanierungen - Sie erhalten zusätzliches Geld.</motion.a>
      </motion.div>      
    </div>
  </div>
  <div className="h-[200px]"></div>
 
   <div className="h-[200px]"></div>
  <div className='container mx-auto' id="VS">
    <div className='flex flex-col lg:flex-row' > 
    <motion.div variants={fadeIn('up', 0.2)} initial="hidden" whileInView={'show'} className=' borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6 amarg'>
        <h1 className='bh2 text-[30px] font-bold  text-center'>Velux - Solarkollektoren, Ihre Vorteile im Überblick</h1>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 0.6)} initial="hidden" whileInView={'show'}>harmonische Integration durch Indach-Montage und VELUX-Design</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 0.8)} initial="hidden" whileInView={'show'}>Vielfache Kombinationsmöglichkieten mit VELUX Dachwohnfenstern</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 1)} initial="hidden" whileInView={'show'}>Einfacher und kostengünstiger Einbau durch bekannte VELUX-Einbautechnik</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 1.2)} initial="hidden" whileInView={'show'}>Einfache Montage. Kompatibel mit VELUX Kombi-Eindeckrahmen</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 1.4)} initial="hidden" whileInView={'show'}>Mit jedem gängigen Heizungssystem kompatibel</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 1.6)} initial="hidden" whileInView={'show'}>Sehr gutes Preis-Leistungs-Verhältnis</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('up', 1.8)} initial="hidden" whileInView={'show'}>Beispielhafte Anlagendimensionierung:</motion.a>
        <motion.a className='pfs1 text-[14px]' variants={fadeIn('up', 1.9)} initial="hidden" whileInView={'show'}>- Haushalt mit 2 Erwachsenen und 2 Kindern: Warmwasserverbrauch 40l / Person<br/>- Dachneigung 30°, Südrichtung, Standort Süddeutschland<br/>- Das Haus benötigt - nach Herstellerangaben - eine 4,04m² effektive Absorberfläche<br/>
- verschiedene Kombinationen sind möglich<br/>
- Eine Möglichkeit: 3 VELUX Solar-Kollektoren, á 114 * 140 m³</motion.a>
      </motion.div>   

      <motion.div variants={fadeIn('down', 0.2)} initial="hidden" whileInView={'show'} className=' borders1 rounded-2xl flex flex-1 flex-col gap-y-6  p-6  '>
        <h1 className='bh2 text-[30px] font-bold  text-center'>Thermie- und Voltaik-Kollektoren</h1>
        <motion.a className='pfs1' variants={fadeIn('down', 0.6)} initial="hidden" whileInView={'show'}>Sonnenkollektoren wirken auf Dächern manchmal wie Fremdkörper - nicht aber bei denen von VELUX und Roto.</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('down', 0.8)} initial="hidden" whileInView={'show'}>Harmonische Anordnung mehrerer Kollektoren und Fernster neben- und übereinander</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('down', 1)} initial="hidden" whileInView={'show'}>Unkomplizierter Einbau, das gilt für die Dachfenster genauso wie für die Solarsysteme</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('down', 1.2)} initial="hidden" whileInView={'show'}>Sollte sich der Energiebedarf Ihres Hauses in Zukunft erhöhen oder ein Dachumbau nötig sein, so lässt sich die Anlage ohne Probleme modulweise auf- oder abrüsten</motion.a>
        <motion.a className='pfs1 font-bold' variants={fadeIn('down', 1.4)} initial="hidden" whileInView={'show'}>Gemeinsam mit unseren Partnern liefern wir Ihnen die Gesamtanlage zu den bei uns üblichen sehr guten technischen Ausführungen und Projektsteuerungen</motion.a>
      </motion.div>
    </div>
  </div>
    


  
<div className="h-[300px]"></div>
</section>
  }