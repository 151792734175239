import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import {motion} from 'framer-motion';

import {fadeIn} from '../variants';
import { Helmet } from 'react-helmet';





const Kontakt = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t5vb4xa', 'template_ps6el48', form.current, 'fw33Bw1Q130Jk7JCJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert ("Email Erfolgreich versendet")
  };

 

  return (
    <div className='contact'>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Kontakt</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Kontakt" />
            </Helmet>
      <div className='maps'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2336.06732985772!2d10.417121462369401!3d54.16117096333071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b267fec65a6bc9%3A0x22cfd80f28f0efed!2sBehrend-Dach%20GmbH%20D%C3%A4cher%20-%20Fassaden%20-%20Abdichtungen!5e0!3m2!1sde!2sde!4v1719778679360!5m2!1sde!2sde" width="1850" height="600" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   </div>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div 
          variants={fadeIn('right', 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 flex justify-start items-center'>
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide '>Get in touch</h4> 
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12 pfs'>Let's work <br/> together! </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form 
          variants={fadeIn('left', 0.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 borders1 rounded-2xl flex flex-col gap-y-6  p-6 items-start' ref={form} onSubmit={sendEmail}>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='Subject' type="text" placeholder='Betreff'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='name' type="text" placeholder='Straße, Hausnummer'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='Firma' type="text" placeholder='Postleitzahl, Ort'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all pfs' name='Telefon' type="text" placeholder='Telefon'/>
            <input className='bg-transparent border-b py-3 outline-none w-full placeholder:text-gray focus:border-accent transition-all' type="email" name='email' placeholder='Email'/>
          
          <textarea className='bg-transparent border-b py-12 pfs outline-none w-full placeholder:text-Gray focus:border-accent transition-all resize-none mb-12' name='message' placeholder=' Ihre Nachricht an uns'></textarea>
          <button className='btn btn-sm text-[20px] h-[60px]' type="submit" value="Send">Absenden</button> 
          </motion.form>
        </div>
        
      </div>
   </div>
  );
};

export default Kontakt;