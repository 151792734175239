import React, { useState, useEffect } from 'react';

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

const Jobs = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://specialtest.behrend-dach.de/wp-json/wp/v2/posts/1');
        const data = await response.json();
        setContent(stripHtmlTags(data.content.rendered));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='bh1 text-center'>
      {content} ignore , this is a test
    </div>
  );
};

export default Jobs;