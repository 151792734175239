import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem2, MenuItem3, MenuItem4 } from "../MenuItem2";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation2 = () => (
  <motion.uls2 variants={variants}>
    {itemIds.map(i => (
      <MenuItem2 i={i} key={i} />
    ))}
  </motion.uls2>
);

export const Navigation3 = () => (
  <motion.uls3 variants={variants}>
    {itemIds.map(i => (
      <MenuItem3 i={i} key={i} />
    ))}
  </motion.uls3>
); 

export const Navigation4 = () => (
  <motion.uls4 variants={variants}>
    {itemIds.map(i => (
      <MenuItem4 i={i} key={i} />
    ))}
  </motion.uls4>
);

const itemIds = [0];