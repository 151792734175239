import React from 'react';
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import Velux from '../assets/Velux.jpg'
import Meyer from '../assets/meyer-holsen.jpg'
import Roto from '../assets/Roto.jpg'
import Rhein from '../assets/Rheinzink.jpg'
import Rath from '../assets/Rathscheck.png'
import Hasse from '../assets/hasse.jpeg'
import alwitra from '../assets/alwitra.png'
import Gutex from '../assets/GUTEX_Logo_50pro_Office.png'
import { Helmet } from 'react-helmet';

export default function Partnerfirmen() {
   return <>
    <Helmet>
                <meta charSet="utf-8" />
                <title>Partnerfirmen</title>
                <link rel="canonical" href="https://www.behrend-dach.de/Partnerfirmen" />
            </Helmet>
     <div  className="text-[78px] HeadlineM Headline flex flex-col"><a className='padd'>unsere</a> Partnerfirmen</div>
  
  <div className="h-[150px]"></div>
  
  <div className='h-[50px]'></div>
  <div  className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1  rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Meyer} className='sizez'/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1 '>Das Unternehmen Dachkeramik Meyer-Holsen blickt auf eine über 160 jährige Firmengeschichte zurück. Beständigkeit, Zuverlässigkeit und Geradlinigkeit sind die besonderen Merkmale des Unternehmens.</a>
        <a className='pfs1'>Aufgrund der konstanten Qualität und Güte der Tondachziegel, die stets weiterentwickelt wird, zählt Meyer-Holsen zu den 5 verbliebenen, eigenständigen Dachziegelproduzenten in Deutschland. Besonders im Bereich Denkmalpflege zeigen sich die Qualität sowie die Flexibilität von Meyer-Holsen bezüglich Form und Farbgebung des Dachziegels.</a>
        <a className='pfs1'>Mit zwei hochmodernen Dachziegelwerken im Kreis Minden-Lübbecke auf dem technisch neuesten Stand garantiert Meyer-Holsen eine zuverlässige Auslieferung von qualitativ hochwertigen Dachziegeln sowie sichere Arbeitsplätze in der heimischen Region. Das Unternehmen befindet sich noch heute im Familienbesitz und wird mit Rüdiger Bethke in der 4. und seinem Sohn Phillip Bethke (technischer Geschäftsführer) in der 5. Generation geführt. Seit Januar 2014 ergänzt Jan Peter Jörn als kaufmännischer Geschäftsführer die Geschäftsleitung.</a>
        <a className='pfs1'>Mit der Nachliefergarantie garantiert Meyer-Holsen für die Dauer von 20 Jahren beginnend mit dem Tage der Auslieferung das in der Urkunde aufgeführte Modell – von rohstoffbedingten Veränderungen abgesehen – nachliefern zu können. Zudem garantiert die Vollgarantie für die Dauer von 40 Jahren ab Lieferdatum die Übernahme der dem ausführenden Fachbetrieb entstehenden Kosten (Tariflöhne, Soziallasten und Hilfsstoffe) sowie die Anlieferung der Dachziegel, und das bietet Meyer-Holsen als einziges Dachziegelwerk in Deutschland.</a>
        <a className='pfs1'>Beginnend beim Naturprodukt aus eigenen Tongruben, über die sorgfältige Produktion und
Oberflächenveredelung mit Engoben oder Glasuren bis hin zur persönlichen Klang- und
Sichtkontrolle – Meyer-Holsen legt größten Wert auf Qualität für Kunden mit höchsten
Ansprüchen.</a>
<motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://www.meyer-holsen.de/'>Besuchen</motion.a>
        
        
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col borders1 lg:flex-row rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Gutex} className=''/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'><span className='font-bold gray text-[20px]'>Unternehmensprofil </span><br/>
GUTEX macht seit 1932 das Beste aus Holz und ist Experte für klimapositive Holzfaserdämmlösungen in den Bereichen Fassade, Dach und Ausbau. Die innovativen Produkte und Systeme bestehen aus Nadelholz von regionaler Forstwirtschaft, welches im Stammwerk in Waldshut-Tiengen nach umweltschonenden Standards verarbeitet wird. Individuelle Beratung und Services erleichtern es dem Großhandel, Architekten, Planern, Handwerkern und Bauherren, behagliche Lebensräume zu schaffen – im Einklang mit der Natur. Das in 4. Generation geführte Familienunternehmen beschäftigt 260 Mitarbeitende und generiert rund 135 Millionen Euro Umsatz im Jahr mit allen Arten der Holzfaserdämmung: Platten, Matten und loser Einblasdämmung.</a>
        <motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://www.gutex.de/'>Besuchen</motion.a>
        
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1 rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Velux} className='sizez '/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'>Die VELUX Deutschland GmbH mit Sitz in Hamburg ist ein Unternehmen der internationalen VELUX Gruppe. VELUX ist der weltweit größte Hersteller von Dachfenstern. Die VELUX Gruppe zählt mit mehr als 10.000 Mitarbeitern sowie Produktionsstandorten in 11 Ländern und Vertriebsgesellschaften in fast 40 Ländern zu den größten Produzenten von Baumaterialien. In Deutschland beschäftigt VELUX in Produktion und Vertrieb nahezu 1.000 Mitarbeiter.</a>
        <a className='pfs1'>Neben Dachfenstern und anspruchsvollen Dachfenstersystemen umfasst die Produktpalette unter anderem Sonnenschutzprodukte, Rollläden sowie Zubehörprodukte für den Fenstereinbau.</a>
        <motion.a whileHover={{ scale: 1.1 }}whileTap={{ scale: 0.9 }} className='pfs1 font-default bh1' href='https://installer-leads.velux.de/booking-form/Behrend-Dach%20GmbH/24306/Pl%C3%B6n'>Formular für eine Beratung bei Ihnen Zuhause</motion.a>
        <motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://www.velux.de/'>Besuchen</motion.a>
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1 rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Roto} className=''/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'>Beispielhafte Verarbeitung, klares Design.
Vollendet durchdacht – die Dachfenster von Roto.
Ob Neubau oder Renovierung. Dachfenster „pur“ oder Kombination mit Solarelementen. Was immer Sie vorhaben, wo immer Sie Ihre Prioritäten setzen: Roto hat die Lösung für Sie. Mit Sicherheit.</a>
        <a className='pfs1'></a>
        <a className='pfs1 ' >Der Inbegriff für Premiumqualität „german made". Nutzen Sie Ihre Wahlfreiheit bei Dachfenstern – mit Roto. </a>
        <div className='h-[25px]'></div>
        <motion.a whileHover={{ scale: 1.1 }}whileTap={{ scale: 0.9 }} className=' font-bold bh1' href='https://www.roto-foerderservice.de/Startseite?action=c6925dfd-9255-415e-a028-fa2d488714cd'>Fördermöglichkeiten für Dachfenster der Firma Roto</motion.a>
        <motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://www.roto-frank.com/de/'>Besuchen</motion.a>
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1 rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Hasse} className='sizez'/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'>
Seit nunmehr 150 Jahren entwickelt und produziert C. Hasse & Sohn Abdichtungsprodukte für Dach und Bauwerk. Die Dachbahn aus Bitumen bildet dabei das Kernprodukt. Über 40 verschiedene Ausführungen decken verschiedenste Anwendungsbereiche sowie Preis- und Qualitätsstufen ab. In Systemen kombiniert ermöglicht diese Vielfalt durchdachte Komplettlösungen, welche mit Systemprodukten wie Dämmung und Bauchemie abgerundet werden. Das Thema Nachhaltigkeit wird aktiv mit Gründach- und stickoxidreduzierenden Produkten unterstützt. Zudem ist C. Hasse & Sohn der Meinung, dass ein Dach nicht nur funktional, sondern auch individuell sein kann: mit unterschiedlichen Dachbahn-Farben oder außergewöhnlichen Systemen wie dem Dänischen Leistendach.</a>
<motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://hasse.info/'>Besuchen</motion.a>
        
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1 rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Rhein} className='sizez2'/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'>
Für Generationen gemacht - wartungsfrei - langlebig - nachhaltig. Seit über 150 Jahren wird Zink für die Anwendung an Dächern eingesetzt. Seit Jahrzehnten kommt es in Form unseres Werkstoffs RHEINZINK auf den Dächern der Welt zum Einsatz. Der Naturwerkstoff hat sich über fast ein halbes Jahrhundert hinweg Prestige erworben, er besticht durch Qualität und überzeugt durch zeitlose Schönheit. Dachdeckungen aus RHEINZINK setzen ein Zeichen und garantieren Funktion und Ästhetik für Generationen. Sie sind absolut pflege- und wartungsfrei und schützen die Bausubstanz auf ästhetische Weise. Mit unseren dachintegrierten Solarlösungen und unserem umfangreichen Dachentwässerungssystem können Ihre Ideen aus einem Guss realisiert werden.</a>
<motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://www.rheinzink.de/'>Besuchen</motion.a>
        
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1 rounded-2xl items-start gap-y-6  p-6'><img alt='' src={Rath} className='sizez3'/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'>Architektur als Ausdruck des Lebensgefühls und als kultureller Maßstab ist einer der besonderen Werte. Sie verbindet Tradition mit Zukunft, Ästhetik mit Funktionalität.
</a>
        <a className='pfs1'>Natürliche, wertbeständige Werkstoffe gewinnen dabei zunehmend an Bedeutung. Und Schiefer gehört von jeher dazu. Ein Werkstoff, direkt und unverfälscht aus der Natur. Generationen von Architekten und Handwerkern schufen mit ihm Baustile, Kunstwerke und Kultur. Diese Tradition hat eine Zukunft, die uns besonders am Herzen liegt.
</a>
        <a className='pfs1  ' > Wir haben das Glück, in Mayen über eines der wertvollsten Schiefervorkommen zu verfügen. Dort gewinnen wir unseren Moselschiefer® – Schiefer von höchster Qualität.</a>
        <motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://www.rathscheck.de/'>Besuchen</motion.a>
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
 
  <div className='h-[50px]'></div>
  <div className='container mx-auto mobiletext'>
    <motion.div variants={fadeIn('right', 0.2)} initial="hidden" whileInView={'show'} className='flex flex-col lg:flex-row borders1 rounded-2xl items-start gap-y-6  p-6'><img alt='' src={alwitra} className='sizez'/>
    <motion.div  className='  flex flex-1 flex-col gap-y-6  p-6 items-start'>
        <a className='pfs1'>Seit 1964 kennen wir nur eine Mission: Das Flachdach sicher und dicht zu machen. Ob das erste mehrteilige Dachrandabschlussprofil, die erste weiße Kunststoffdachbahn oder die erste Solardachbahn: Mit zahlreichen Innovationen haben wir die Flachdachwelt entscheidend geprägt und in puncto Qualität, Langlebigkeit und Nachhaltigkeit den Weg in die Zukunft gewiesen. Die Verwirklichung dieser Visionen wäre ohne den intensiven Austausch mit unseren Kunden und Partnern nicht möglich gewesen.</a>
        <motion.a whileHover={{ scale: 1.2 }}whileTap={{ scale: 0.9 }} className='bh1 font-bold text-[30px] ' href='https://alwitra.de/'>Besuchen</motion.a>
        
        
      </motion.div>
    </motion.div>
  </div>
  <div className='h-[50px]'></div>
  
  <div className='h-[150px]'></div>
</>

  }