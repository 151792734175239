import React from 'react';
import {TypeAnimation} from 'react-type-animation' 
import {motion} from 'framer-motion' 
import {fadeIn} from '../variants' 
import { Link } from 'react-scroll';
import { useCallback } from 'react';
import {
    ReactCompareSlider,
    ReactCompareSliderHandle,
    ReactCompareSliderImage
  } from "react-compare-slider";
  import oldDach from '../assets/oldDach.webp'
import newDach from '../assets/newDach.webp'
import oldDach2 from '../assets/oldDach2.webp'
import newDach2 from '../assets/newDach2.webp'
import Dach4 from '../assets/Neuaufbau.jpg'
import Mus from '../assets/Mus.png'
import PV1 from '../assets/Photov1.jpeg'
import PV2 from '../assets/Photov2.jpeg'
import PV3 from '../assets/Photov3.jpeg'
import PV4 from '../assets/Photov4.jpeg'
import SAN from '../assets/Saniert.jpg'
import '../App.scss'



export const Ref1 = ( style, ...props ) => {
    const handlePositionChange = useCallback(
        (position) => console.log("[Portrait]", position),
        []
      );
    return ( <div className=' container'>
       
       <motion.div
        className="Ref1 flex h-screen items-center justify-center" 
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.8,
          delay: 0.1,
          ease: [1, 0.71, 0.2, 1.01]
        }}
      >
        <div className='h-[300px]'></div>
        <div className='text-center margin-auto  '>
        <motion.a variants={fadeIn('up', 2.8)} initial="hidden" animate={'show'} className='bh1 text-[60px] text-center font-bold'>Bauvorhaben:</motion.a>
        <div className='h-[50px]'></div>
        <motion.a variants={fadeIn('up', 3)} initial="hidden" animate={'show'} className='bh2 text-[40px] font-bold'>Einfache Sanierung eines Steildaches</motion.a>
        <div className='h-[50px]'></div>
        <Link to='Info' activeClass='active' smooth={true} spy={true} offset={-200}>
        <motion.svg id="more-arrows" className='cursor-pointer mx-auto' variants={fadeIn('up', 3.2)} initial="hidden" animate={'show'}  whileHover={{ scale: 1.1 }}whileTap={{ scale: 0.9 }}>
        <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
        <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
        <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
      </motion.svg></Link>
        </div>
        
      </motion.div>
      <div className='h-[200px]'></div>
      
  <div id='Info' className='container mx-auto text-center margin68'>
  <h1 className='bh2 text-[30px] h-[70px] font-bold'>Vor und nach der Sanierung</h1> 
  
  
  <ReactCompareSlider className='container mx-auto shadow1 flex h-screen w-full  justify-center items-center' 
      {...props}
      handle={
        <ReactCompareSliderHandle
          buttonStyle={{
            backdropFilter: undefined,
            background: "white",
            border: 0,
            color: "#333"
          }}
        />
      }
      itemOne={
        <ReactCompareSliderImage
          src={oldDach}
          alt="one" 
        />
       
      }
      itemTwo={
        
        <ReactCompareSliderImage
          src={newDach}
          alt="two"
        /> 
      }
      onPositionChange={handlePositionChange}
      style={{
        display: "flex",
        width: "100%",
        height: "50vh",
        ...style
      }}
    />
      </div>
      <div className='h-[200px]'></div>
      <div className='container mx-auto margin-auto anothermargin' >
    <div className='flex flex-col lg:flex-row' ><div className='flex-1 flex flex-col  items-center '>
      <a className='flex ' >
      <motion.img variants={fadeIn('right', 0.5)} initial="hidden" whileInView={'show'}  whileHover={{ scale: 1.05 }}whileTap={{ scale: 0.9 }}  src={Dach4} alt='' className='showcase rounded-xl borders1'/>
      
      </a>
      
      
      </div>
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='  rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start shadow1'>
        <h1 className='bh2 text-[35px] font-bold '>Nähere Details zum Dach</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>- Energetische Dachsanierung mit einer PIR-Aufdachdämmung, 160 mm dick</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.7)} initial="hidden" whileInView={'show'}>- Eindeckung mit <span className='bh1'> Meyer-Holsen</span>, Rundschnitt Biber, graphit, Doppeldeckung</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>- Schornsteinkopf mit Schiefer, 30/30 cm, Bogenschnitt, waagerecht gedeckt</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.9)} initial="hidden" whileInView={'show'}>- Schornsteinkopfeinfassung mit Blei-Schichtstücken ausgebildet, Schürze und Nackenkehle eingefalzt</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}>- Traufen und Ortgänge geschiefert</motion.a>
        
      </motion.div>
      
      

    </div>
    
  </div>
  <div className='h-[400px]'></div>
</div>
    );
  };
  

  export const Ref2 = ( style, ...props ) => {
    
    
     
    return ( <div className=' container'>
       
         <motion.div
        className="Ref2" 
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.8,
          delay: 0.1,
          ease: [1, 0.71, 0.2, 1.01]
        }}
      >
        <div className='h-[300px]'></div>
        <div className='text-center margin-auto'>
        <motion.a variants={fadeIn('up', 2.8)} initial="hidden" animate={'show'} className='bh1 text-[60px] font-bold'>Bauvorhaben:</motion.a>
        <div className='h-[50px]'></div>
        <motion.a variants={fadeIn('up', 3)} initial="hidden" animate={'show'} className='bh2 text-[40px] font-bold'>Marineunteroffiziersschule IN PLÖN</motion.a>
        <div className='h-[50px]'></div>
        <Link to='Info' activeClass='active' smooth={true} spy={true} offset={-200}>
        <motion.svg id="more-arrows" className='cursor-pointer mx-auto' variants={fadeIn('up', 3.2)} initial="hidden" animate={'show'}  whileHover={{ scale: 1.1 }}whileTap={{ scale: 0.9 }}>
        <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
        <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
        <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
      </motion.svg></Link>
        </div>
        
      </motion.div>
      <div className='h-[200px]'></div>
      
  <div id='Info' className='container mx-auto text-center margin68'>
   
  
  
  
      </div>
      <div className='h-[200px]'></div>
      <div className='container mx-auto anothermargin' >
    <div className='flex flex-col lg:flex-row' ><div className='flex-1 flex flex-col  items-center '>
      <a className='flex ' >
      <motion.img variants={fadeIn('right', 0.5)} initial="hidden" whileInView={'show'}  whileHover={{ scale: 1.05 }}whileTap={{ scale: 0.9 }}  src={Mus} alt='' className='showcase rounded-xl borders1'/>
      
      </a>
      
      
      </div>
    <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='  rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start shadow1'>
        <h1 className='bh2 text-[35px] font-bold '>Nähere Details zum Dach</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Diese ehemals als ungedämmte konzipierte Werkhalle wurde Dach- und Fassadentechnisch komplett saniert.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.7)} initial="hidden" whileInView={'show'}>Die vorhandene Dacheindeckung aus einschaligen Stahl-Wellprofilen wurde demontiert und durch ein hochdämmendes Verbundelement, bestehend aus kunstoffbeschichteten Stahlblechdeckschalen mit einem Polyurethan-Kern, Kerndicke: 100 mm (Gesamtdicke: 135 mm), ersetzt.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>Die Fassadenverkleidung, die ebenfalls im Bestand aus einem einschaligem Wellprofil vorhanden war, wurde durch ein hochdämmendes Verbundelement, bestehend aus kunstoffbeschichteten Stahlblechdeckschalen mit einem Polyurethan-Kern, Kerndicke: 100 mm ersetzt.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.9)} initial="hidden" whileInView={'show'}>- Schornsteinkopfeinfassung mit Blei-Schichtstücken ausgebildet, Schürze und Nackenkehle eingefalzt</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 1)} initial="hidden" whileInView={'show'}>Als Belichtungsfläche wurde eine durchgängige Lichtbandlösung aus mehrschichtigen Polycarbonat-Lichtplatten mit thermisch getrennten Aluminiumprofilen realisiert.</motion.a>
        
      </motion.div>
      
      

    </div>
    
  </div>
  <div className='h-[400px]'></div>
</div>
    );
  };


  export const Ref3 = ( style, ...props ) => {
    
    
     
    return ( <div className=' container'>
       
         <motion.div
        className="Ref3" 
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.8,
          delay: 0.1,
          ease: [1, 0.71, 0.2, 1.01]
        }}
      >
        <div className='h-[300px]'></div>
        <div className='text-center margin-auto'>
        <motion.a variants={fadeIn('up', 2.8)} initial="hidden" animate={'show'} className='bh1 text-[60px] font-bold fullbright'>Bauvorhaben:</motion.a>
        <div className='h-[50px]'></div>
        <motion.a variants={fadeIn('up', 3)} initial="hidden" animate={'show'} className='bh2 text-[40px] font-bold fullbright'>Montage einer 10 kWp-Anlage in einem alten Hohlpfannendach.</motion.a>
        <div className='h-[50px]'></div>
        <Link to='Info' activeClass='active' smooth={true} spy={true} offset={-200}>
        <motion.svg id="more-arrows" className='cursor-pointer mx-auto' variants={fadeIn('up', 3.2)} initial="hidden" animate={'show'}  whileHover={{ scale: 1.1 }}whileTap={{ scale: 0.9 }}>
        <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
        <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
        <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
      </motion.svg></Link>
        </div>
        
      </motion.div>
      <div className='h-[200px]'></div>
      
  <div id='Info' className='container mx-auto text-center '>
      </div>
      <div className='container margin-auto2'>
          <div className='flex flex-col lg:flex-row gap-x-10 '>
          <motion.div className='flex-1 flex flex-col gap-y-10' 
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            >
              {/*image*/}
              <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={PV1} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </div> 
               <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={PV2} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </div>     
            </motion.div>
            <div className='h-[50px]'></div>
            <motion.div className='flex-1 flex flex-col gap-y-10' 
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.3}}
            >
              {/*image*/}
              <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={PV3} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </div> 
               <div className='group relative overflow-hidden border-2 border-black/50 rounded-xl'>
                   {/*overlay*/}
                  <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                  {/* img */}
                  <img className='group-hover:scale-125 transition-all duration-500' src={PV4} alt='' />
                  {/* prettitle */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                   <span className='text-gradient'></span> 
                  </div>
                  {/* title */}
                  <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                    <span className='text-3x1 text-white  '></span>
                  </div>
               </div>     
            </motion.div>
          </div>
      </div>
      <div className='h-[200px]'></div>
      
  <div className='h-[400px]'></div>
</div>
    );
  };
  
  export const Ref4 = ( style, ...props ) => {
    
    const handlePositionChange = useCallback(
      (position) => console.log("[Portrait]", position),
      []
    );
     
    return ( <div className=' container'>
       
         <motion.div
        className="Ref4" 
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.8,
          delay: 0.1,
          ease: [1, 0.71, 0.2, 1.01]
        }}
      >
        <div className='h-[300px]'></div>
        <div className='text-center margin-auto'>
        <motion.a variants={fadeIn('up', 2.8)} initial="hidden" animate={'show'} className='bh1 text-[60px] font-bold fullbright'>Bauvorhaben:</motion.a>
        <div className='h-[50px]'></div>
        <motion.a variants={fadeIn('up', 3)} initial="hidden" animate={'show'} className=' text-[40px] font-bold pfs bgw2 fullbright'>Energetische Sanierung eines Einfamilienwohnhauses</motion.a>
        <div className='h-[50px]'></div>
        <Link to='Info' activeClass='active' smooth={true} spy={true} offset={-200}>
        <motion.svg id="more-arrows" className='cursor-pointer mx-auto' variants={fadeIn('up', 3.2)} initial="hidden" animate={'show'}  whileHover={{ scale: 1.1 }}whileTap={{ scale: 0.9 }}>
        <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
        <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
        <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
      </motion.svg></Link>
        </div>
        
      </motion.div>
      <div className='h-[200px]'></div>
      
  <div id='Info' className='container mx-auto text-center margin68'>
   </div>
   <div id='Info' className='container mx-auto text-center margin68'>
  <h1 className='bh2 text-[30px] h-[70px] font-bold'>Vor und nach der Sanierung</h1> 
  
  
  <ReactCompareSlider className='container mx-auto shadow1' 
      {...props}
      handle={
        <ReactCompareSliderHandle
          buttonStyle={{
            backdropFilter: undefined,
            background: "white",
            border: 0,
            color: "#333"
          }}
        />
      }
      itemOne={
        <ReactCompareSliderImage
          src={oldDach2}
          alt="one" 
        />
       
      }
      itemTwo={
        
        <ReactCompareSliderImage
          src={newDach2}
          alt="two"
        /> 
      }
      onPositionChange={handlePositionChange}
      style={{
        display: "flex",
        width: "100%",
        height: "50vh",
        ...style
      }}
    />
      </div>

      <div className='h-[300px]'></div>
      <div className='container mx-auto anothermargin' >
    <div className='flex flex-col lg:flex-row' >
      <div className='flex-1 flex flex-col  items-center '>
      <a className='flex ' >
      <motion.img variants={fadeIn('right', 0.5)} initial="hidden" whileInView={'show'}  whileHover={{ scale: 1.05 }}whileTap={{ scale: 0.9 }}  src={SAN} alt='' className='showcase rounded-xl borders1'/>
      
      </a>
      
      
      </div>

      <motion.div variants={fadeIn('left', 0.2)} initial="hidden" whileInView={'show'} className='  rounded-2xl flex flex-1 flex-col gap-y-6  p-6 items-start shadow1'>
        <h1 className='bh2 text-[35px] font-bold '>Nähere Details zum Dach</h1>
        <motion.a className='pfs1' variants={fadeIn('left', 0.6)} initial="hidden" whileInView={'show'}>Die vorhandene Dacheindeckung aus Betondachsteinen einschließlich Dachlattung sowie die vorhandene Glaswolle wurden zurückgebaut.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.7)} initial="hidden" whileInView={'show'}>Eine luftdichte Gebäudehülle wurde erstellt, anschließend das Dach mit Polyurethan-Dämmplatten als Aufdachdämmung gedämmt.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.8)} initial="hidden" whileInView={'show'}>Die Giebel sowie Traufen wurden mit Schiefer verkleidet, die Unterschläge mit einer hellgrauen Schichtstoffplatte.</motion.a>
        <motion.a className='pfs1' variants={fadeIn('left', 0.9)} initial="hidden" whileInView={'show'}>Die Eindeckung erfolgte mit einem hochwertigen Ziegel der Firma Meyer-Holsen, Typ Zwilling natur</motion.a>
        
        
      </motion.div>
      
    </div>
  </div>
  <div className='h-[400px]'></div>
</div>
    );
  };