import React from "react"; 
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import Logo  from '../src/assets/better_logo.jpg' 
import qrcode from '../src/assets/qr-code 2.svg'
 

const Footer = () => {
    return(
        <footer className="flex">
        <div class="container">
          <ul>
            <li><a className="font-bold">Behrend-Dach GmbH</a></li>
            <li><a >Dachdeckermeister Daniel Bartnik</a></li>
            <li><a >24306 Plön - Lübecker Str. 4</a></li>
            <li><a >Telefon: 04522 802 960</a></li>
            <li><a >Email: info@behrend-dach.de</a></li>
            <li><a >Designed by : <a href="https://jeremiah-coder.web.app/"><img alt=""  className="add"  src={Logo} /></a></a></li>
            <li><a><Link to="/Impressum" >
        <button  >Impressum</button>
        </Link><div className="w-[20px]"></div>
        <Link to="/Datenschutz">
        <button  >Datenschutz</button>
        </Link></a></li>
          </ul>
        </div>
        <div className="flex-col">
        <img className="scale-75"  src={qrcode}></img>
        <h1 className="text-[30px]">oder unter: <a href="https://tinyurl.com/bdefpsnc" className="bh1 ">Hier</a></h1>
        </div>
      </footer>
    )
}

export default Footer;

